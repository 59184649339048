<template>
  <div>
    <audio src="@/assets/audios/alertaPanico.mp3"></audio>
    <div style="height: 100vh; font-family: helvetica; background: radial-gradient(#ffffff, #aaaaaa);">
      <div class="px-3 py-2 ">
        <div class="d-flex justify-content-center align-items-center" style=" flex-direction: column;">
          <div class="w-100 text-center" style="max-height: 10vh;">
            <span style="font-weight: 700; color: #222; font-size: 5vh;">{{ data }}</span> 
            <span style="font-weight: 700; color: #222; font-size: 5vh;" class="ml-1">{{ hora }}</span>
          </div>
          <div class="w-100 text-center mt-3" style="max-height: 10vh;">
            <span style="color: #222; font-weight: 700; font-size: 5vh; line-height: 5vh;">Nome</span><br>
            <span style="font-weight: 700; color: #c22; font-size: 18vh; line-height: 20vh;" class="w-100">
              {{ usuario }}
            </span><br>
            <span style="color: #222; font-weight: 700; font-size: 5vh; line-height: 5vh;">Local</span><br>
            <span style="font-weight: 700; color: #c22; font-size: 18vh; line-height: 20vh;" class="w-100">
              {{ local }}
            </span><br>
            <span style="color: #222; font-weight: 700; font-size: 5vh; line-height: 5vh;">Horário</span><br>
            <span style="font-weight: 700; color: #222; font-size: 15vh; line-height: 20vh;" class="w-100">
              {{ horario }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      detecta_chamada: "",
      usuario: "",
      local: "",
      horario: "",
      data: "",
      hora: "",
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    
    getHorario: function(){
      const self = this;

      setInterval(() => {
        const date = new Date();
        let ano = date.getFullYear();
        let mes = date.getMonth()+1;
        let dia = date.getUTCDate();
        let horas = date.getHours();
        let minutos = date.getMinutes();
        let segundos = date.getSeconds();

        self.hora = horas.toString().padStart(2, '0') + ":" + minutos.toString().padStart(2, '0') + ":" + segundos.toString().padStart(2, '0');
        self.data = dia.toString().padStart(2, '0') + "/" + mes.toString().padStart(2, '0') + "/" + ano;
      }, 1000);
    },
    chamarSenha: function(){
      const self = this;
      self.chamando = 1;

      $('audio').trigger("play");

      setTimeout(function(){
        self.chamando = 0;
      }, 1500);
    },
    getPanicos: function(){
      const self = this;
      const api = self.$store.state.api + "panicos?orderByDesc=created_at&with[]=local&with[]=usuario"

      axios
        .get(api)
        .then((response) => {
          response = response.data.data;

          if(self.detecta_chamada !== response[0].id && self.detecta_chamada !== "")
          {
            self.chamarSenha();
          }

          if(response[0].local_id)
          {
            self.local = response[0].local.nome;
          }else{
            self.local = "Não informado";
          }

          self.usuario = response[0].usuario.name;
          self.horario = response[0].created_at_f;
          self.detecta_chamada = response[0].id;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;

    self.getHorario();

    setInterval(() => {
      self.getPanicos();
    }, 10000)
  },
  created() {},
};
</script>

<style>
html, body{
  font-family: helvetica;
}
@-webkit-keyframes fadeIn { 0% { opacity: 0; } 100% { opacity: 1; } }
@-moz-keyframes fadeIn { 0% { opacity: 0;} 100% { opacity: 1; } }
@-o-keyframes fadeIn { 0% { opacity: 0; } 100% { opacity: 1; } }
@keyframes fadeIn { 0% { opacity: 0; } 100% { opacity: 1; } }

.fadeIn {
    -webkit-animation: fadeIn 1.3s ease-in-out;
    -moz-animation: fadeIn 1.3s ease-in-out;
    -o-animation: fadeIn 1.3s ease-in-out;
    animation: fadeIn 1.3s ease-in-out;
}

body {overflow-x: hidden}
div > #mensagens {display: relative; width: 200%; grid-template-columns: repeat(2, .25fr); justify-content: space-between; 
transform: translateX(100z); animation: anima 90s linear infinite}
#mensagens:before, #mensagens:after {content: ""; display: relative;}
#mensagens:after {transform: translate3d(100%, 0, 0)}
@keyframes anima{
    from
    {
        transform: translateX(110%)
    } 
    to
    {
        transform: translateX(-160%)
    }
}
</style>
